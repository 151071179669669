<template>
  <v-dialog v-model="modalEdit" :max-width="dekstop">
    <v-card>
      <v-toolbar dark color="utama" dense flat>
        <v-toolbar-title>Edit Data user</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="pa-5">
          <v-row>
            <!-- NIP -->
            <v-col cols="12" md="12" class="mb-n9">
              <v-text-field
                label="NIP"
                v-model="editedItem.nipBaru"
                filled
                dense
                flat
                outlined
                class="mt-2"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- Nama -->
            <v-col cols="12" md="12" class="mb-n9">
              <v-text-field
                label="Nama"
                v-model="nama"
                filled
                dense
                flat
                outlined
                class="mt-2"
                :loading="fieldLoading"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- User Password -->
            <v-col cols="12">
              <v-text-field
                label="Password"
                v-model="password"
                dense
                flat
                outlined
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                class="mt-2 input-group--focused"
                @click:append="show = !show"
              ></v-text-field>
            </v-col>
          </v-row>

          <hr />
          <v-row dense class="mt-2">
            <!-- Password Saya -->
            <v-col cols="12" md="9">
              <v-text-field
                label="Password Saya"
                placeholder="Masukan Password Anda"
                v-model="mypassword"
                dense
                flat
                outlined
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                class="mt-2 input-group--focused"
                @click:append="show = !show"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <div class="text-right mt-3">
                <v-btn
                  v-if="btnLoading"
                  dark
                  color="utama"
                  depressed
                  @click="edit()"
                >
                  SIMPAN
                </v-btn>
                <v-btn v-else color="utama" depressed loading>SIMPAN</v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";

import modalEdit from "@/store/user/modalEdit";
import refreshView from "@/store/user/viewUser";

export default {
  created() {
    if (
      this.$vuetify.breakpoint.name === "xs" ||
      this.$vuetify.breakpoint.name === "sm"
    ) {
      this.dekstop = "100%";
    }
  },

  computed: {
    modalEdit: {
      get() {
        return modalEdit.state.modalEdit;
      },
      set(value) {
        modalEdit.commit("toggleModal", value);
      },
    },

    editedItem: {
      get() {
        return modalEdit.state.user;
      },
      set(value) {
        console.log(value);
      },
    },
  },

  watch: {
    async modalEdit() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.getASN();
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    session: "",
    dekstop: "40%",

    fieldLoading: true,
    btnLoading: true,
    show: false,

    idUser: "",
    nama: "",
    password: "",
    mypassword: "",
  }),

  methods: {
    getASN() {
      const url =
        process.env.VUE_APP_AUTH + "security/asn/" + this.editedItem.nipBaru;
      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.idUser = response.data.mapData.idASN;
          this.nama = response.data.mapData.nama;
          this.fieldLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async edit() {
      this.btnLoading = false;

      const data = {
        myPassword: this.mypassword,
        password: this.password,
      };

      const url =
        process.env.VUE_APP_AUTH + "security/asn/" + this.editedItem.nipBaru;
      this.http
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.btnLoading = true;
          this.closeModal();
        });
    },

    onFile(value) {
      this.user_foto = value;
      this.urlImage = URL.createObjectURL(this.user_foto);
    },

    rubahOPD(value) {
      this.editedItem.user_opd_id = value.UNOR_ID;
      this.editedItem.user_opd_nama = value.UNOR_NAMA;
    },

    closeModal() {
      this.password = "";
      this.modalEdit = false;
    },
  },
};
</script>
