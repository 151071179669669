import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modalHapus: false,
        user: {
            idUser: "",
            nama: "",
            nipBaru: "",
            unor: {
                id: "",
                nama: "",
            },
            status: "",
            foto: "",
            NCSISTIME: "",
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.modalHapus = value
        },
        viewModal(state, value) {
            state.user = value
        }
    }
})
