<template>
  <v-dialog v-model="ModalAdd" :max-width="dekstop">
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            fab
            text
            v-bind="attrs"
            v-on="{ ...tooltip, ...modal }"
            @click="openModal()"
          >
            <v-icon color="utama">mdi-plus-box</v-icon>
          </v-btn>
        </template>
        <span>Tambah Data</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="utama" dense flat>
        <v-toolbar-title>Tambah User</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="px-5 pt-5">
          <v-row>
            <!-- NIP -->
            <v-col cols="12" md="12" class="mb-n9">
              <v-text-field
                label="NIP"
                v-model="userName"
                dense
                flat
                outlined
                class="mt-2"
                append-icon="mdi-account-search-outline"
                @click:append="getASN()"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- Nama -->
            <v-col cols="12" md="12" class="mb-n9">
              <v-text-field
                label="Nama"
                v-model="nama"
                filled
                dense
                flat
                outlined
                class="mt-2"
                :loading="fieldLoading"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- User Password -->
            <v-col cols="12">
              <v-text-field
                label="Password"
                v-model="password"
                dense
                flat
                outlined
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                class="mt-2 input-group--focused"
                @click:append="show = !show"
              ></v-text-field>
            </v-col>
          </v-row>

          <hr />
          <div class="text-right mt-5 pb-5">
            <v-btn
              v-if="btnLoading"
              dark
              color="utama"
              depressed
              @click="add()"
            >
              SIMPAN
            </v-btn>
            <v-btn v-else dark color="utama" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import refreshView from "@/store/user/viewUser";

export default {
  created() {
    if (
      this.$vuetify.breakpoint.name === "xs" ||
      this.$vuetify.breakpoint.name === "sm"
    ) {
      this.dekstop = "100%";
    }
  },

  data: () => ({
    token: Cookie.get("token"),
    dekstop: "40%",

    ModalAdd: false,
    btnLoading: true,
    fieldLoading: false,
    show: false,

    refLevel: [],
    refOpd: [],

    idUser: "",
    userName: "",
    nama: "",
    password: "",
  }),

  methods: {
    default() {
      this.idUser = "";
      this.userName = "";
      this.nama = "";
      this.password = "";
    },

    async openModal() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.ModalAdd = true;
    },

    closeModal() {
      this.default();
      this.ModalAdd = false;
    },

    getASN() {
      this.fieldLoading = true;
      const url = process.env.VUE_APP_AUTH + "security/asn/" + this.userName;
      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.idUser = response.data.mapData.idASN;
          this.nama = response.data.mapData.nama;
          this.fieldLoading = false;
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    async add() {
      this.btnLoading = false;

      const data = {
        idUser: this.idUser,
        userName: this.userName,
        password: this.password,
        status: 1,
      };

      const url = process.env.VUE_APP_AUTH + "security/users";
      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.default();
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.btnLoading = true;
          this.default();
          this.closeModal();
        });
    },

    onFile(value) {
      this.user_foto = value;
      this.urlImage = URL.createObjectURL(this.user_foto);
    },
  },
};
</script>
